import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.7_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.3/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { mauricio, michael, cory, berenice, daniela, rayane, yuriy, liliana, olha, dariia, dmitry, alexander, vladislav } from './reviewers';
export default [_objectSpread(_objectSpread({}, yuriy), {}, {
  quote: `I'm constantly using their charts to visualize my project's progress. It's a quick, easy, and visually appealing way to communicate information!`,
  score: 5
}), _objectSpread(_objectSpread({}, dariia), {}, {
  quote: `As a designer I've played with tons of data visualization tools and the experience is always clunky and the results feel underwhelming. Couldn't be further from the truth here, I'm constantly surprised by how accessible good design is.`,
  score: 5
}), _objectSpread(_objectSpread({}, rayane), {}, {
  quote: 'Seamlessly takes all our data and delivers polished graphs and dashboards everywhere. Especially love their WordPress plugin, saves so much time.',
  score: 4.5
}), _objectSpread(_objectSpread({}, olha), {}, {
  quote: `My go-to tool for turning static data into presentation-ready data visualizations. You can let the tool do the heavy lifting to deliver a highly impactful experience.`,
  score: 4.5
}), _objectSpread(_objectSpread({}, vladislav), {}, {
  quote: `Chart Maker is easy to use and creates professional charts effortlessly. With smooth Google Drive integration, saving and sharing is seamless. A great choice for quick, high quality charts!`,
  score: 5
}), _objectSpread(_objectSpread({}, liliana), {}, {
  quote: `The ability to create professional-looking charts directly from my Google Sheets has been a game changer. The intuitive workflow makes it so easy to reveal the insights I'm trying to share with my team.`,
  score: 5
}), _objectSpread(_objectSpread({}, michael), {}, {
  quote: `I'm blown away at how easy it is to convert my Excel data into interactive charts. The process is smooth, and the results look great on my website. Highly recommend!`,
  score: 5
}), _objectSpread(_objectSpread({}, cory), {}, {
  quote: 'As a business owner, I appreciate the easy-to-use templates and the ability to embed charts without needing technical skills. Fast, beautiful, and low-effort. A must-have tool.',
  score: 5
}), _objectSpread(_objectSpread({}, alexander), {}, {
  quote: `When it comes to communicating data efficiently and effectively, stop and look no further. Whether we're assessing sprint velocity or project financials it's just so easy to make it look so good.`,
  score: 5
}), _objectSpread(_objectSpread({}, berenice), {}, {
  quote: 'Their graphs and my workflow have become 100% inseparable. Both for my deliverables and client communication, they’ve added dramatically more professional data design to everything.',
  score: 5
}), _objectSpread(_objectSpread({}, daniela), {}, {
  quote: 'These chart builders are super easy to use! I love how fast I can create interactive charts. It saves me a ton of time and makes my data look great!',
  score: 5
}), _objectSpread(_objectSpread({}, mauricio), {}, {
  quote: `Started using their graphing tools to present my client's quarterly performance metrics and haven't stopped. It feels like magic presenting such professional visualizations, feels like I've hired a designer.`,
  score: 5
}), _objectSpread(_objectSpread({}, dmitry), {}, {
  quote: `Their graphing tools are a solid choice for anyone looking for a functional and data-driven visualization tool. It makes it so easy to add pro-level design to all your data.`,
  score: 5
})];