export const mauricio = {
  authorName: 'Mauricio Castro',
  avatarUrl: '/img/authors/mauricio-castro.jpg',
  companyName: 'NSCA Certified Personal Trainer',
  companyUrl: 'https://www.linkedin.com/in/mauricio-castro-2598b1171/'
};
export const michael = {
  authorName: 'Michael Franco',
  avatarUrl: '/img/authors/michael-franco.jpg',
  companyName: 'People Operations & HR Business Partner',
  companyUrl: 'https://www.linkedin.com/in/michaelrfranco/'
};
export const cory = {
  authorName: 'Cory Blumenfeld',
  avatarUrl: '/img/authors/cory-blumenfeld.jpg',
  companyName: '4x Startup Founder',
  companyUrl: 'https://www.linkedin.com/in/coryblumenfeld/'
};
export const berenice = {
  authorName: 'Berenice Laciar',
  avatarUrl: '/img/authors/berenice-laciar.webp',
  companyName: 'Video Editor - Post Production Coordinator',
  companyUrl: 'https://www.linkedin.com/in/berenice-laciar/'
};
export const daniela = {
  authorName: 'Daniela Parra',
  avatarUrl: '/img/authors/daniela-parra.webp',
  companyName: 'Business Office Manager',
  companyUrl: 'https://www.linkedin.com/in/danielaparragonzalez/'
};
export const rayane = {
  authorName: 'Rayane De Sousa Mesquita',
  avatarUrl: '/img/authors/rayane-de-sousa-mesquita.webp',
  companyName: 'Content Marketer/ SEO Content Writer',
  companyUrl: 'https://www.linkedin.com/in/rayanedesousamesquita/'
};
export const yuriy = {
  authorName: 'Yuriy Lazebnyi',
  avatarUrl: '/img/authors/yuriy-lazebnyi.webp',
  companyName: 'Chief Business Development Officer',
  companyUrl: 'https://www.linkedin.com/in/lazebnyi/'
};
export const liliana = {
  authorName: 'Liliana Martínez',
  avatarUrl: '/img/authors/liliana-martinez.webp',
  companyName: 'Backend Developer',
  companyUrl: 'https://www.linkedin.com/in/liliana-martinez-240490/'
};
export const olha = {
  authorName: 'Olha Kutyshenko',
  avatarUrl: '/img/authors/olha-kutyshenko.webp',
  companyName: 'Product Designer',
  companyUrl: 'https://www.linkedin.com/in/olha-kutyshenko-36a233101/'
};
export const dariia = {
  authorName: 'Dariia Katolik',
  avatarUrl: '/img/authors/dariila-katolik.webp',
  companyName: 'UI/UX Designer',
  companyUrl: 'https://www.linkedin.com/in/dariia-katolik-936452ba/?locale=en_US'
};
export const dmitry = {
  authorName: 'Dmitry Oborsky',
  avatarUrl: '/img/authors/dmitry-oborsky.webp',
  companyName: 'Software Engineer',
  companyUrl: 'https://www.linkedin.com/in/dmitry-oborsky-8424b5124/'
};
export const alexander = {
  authorName: 'Alexander Belkin',
  avatarUrl: '/img/authors/alexander-belkin.webp',
  companyName: 'Project Manager',
  companyUrl: 'https://www.linkedin.com/in/alex-belkin/'
};
export const vladislav = {
  authorName: 'Vladislav Godunov',
  avatarUrl: '/img/authors/vladislav-godunov.webp',
  companyName: 'Senior Frontend Developer',
  companyUrl: 'https://www.linkedin.com/in/vladislav-godunov-44bb02145/'
};