import donutChartMaker from '@/features/resources/DonutChartMaker/settings';
import pieChartMaker from '@/features/resources/PieChartMaker/settings';
import bubbleChartMaker from '@/features/resources/BubbleChartMaker/settings';
import scatterPlotMaker from '@/features/resources/ScatterPlotMaker/settings';
import barGraphMaker from '@/features/resources/BarGraphMaker/settings';
import lineGraphMaker from '@/features/resources/LineGraphMaker/settings';
import horizontalBarGraphMaker from '@/features/resources/HorizontalBarGraphMaker/settings';
import doubleBarGraphMaker from '@/features/resources/DoubleBarGraphMaker/settings';
import stackedBarGraphMaker from '@/features/resources/StackedBarGraphMaker/settings';
import areaChartMaker from '@/features/resources/AreaChartMaker/settings';
import gaugeChartMaker from '@/features/resources/GaugeChartMaker/settings';
import radarChartMaker from '@/features/resources/RadarChartMaker/settings';
export { donutChartMaker, pieChartMaker, bubbleChartMaker, scatterPlotMaker, barGraphMaker, lineGraphMaker, horizontalBarGraphMaker, doubleBarGraphMaker, stackedBarGraphMaker, areaChartMaker, gaugeChartMaker, radarChartMaker };
export default [donutChartMaker, pieChartMaker, bubbleChartMaker, scatterPlotMaker, barGraphMaker, lineGraphMaker, horizontalBarGraphMaker, doubleBarGraphMaker, stackedBarGraphMaker, areaChartMaker, gaugeChartMaker, radarChartMaker];