import generalConfig from '@/config/general';
import reviews from '../../../content/graphMakerReviews';
export default {
  createdAt: 1690217006,
  updatedAt: 1733501018,
  slug: 'pie-chart-maker',
  heading: 'Pie Chart Maker',
  title: 'Online Pie Chart Maker - Free, No Signup',
  description: 'Create customizable pie charts in your browser for free. Import data from Excel, Google Sheets & Drive. Download in any size or file format. No signup necessary.',
  shortDescription: 'Create a free pie chart. Import data from Excel & Google Sheets. Customize then download below. No signup necessary.',
  directory: 'Charts',
  directoryUrl: '/charts',
  chartAdapterType: 'Pie',
  basicDataEditorColumnStructure: {
    labelColumn: 'Labels',
    subColumns: [{
      label: 'Ring'
    }]
  },
  images: {
    resourceUrl: '/img/pages/pie-chart-maker/featured-image.webp',
    resourceUrlAlt: 'Pie chart next to a legend of the category titles',
    twitterPost: '/img/pages/pie-chart-maker/twitter-post.webp',
    openGraph: '/img/pages/pie-chart-maker/og-image.webp'
  },
  icon: '/icons/chart-pie.svg',
  reviews,
  articleSections: [{
    id: 'what-is-a-pie-chart',
    name: 'What Is a Pie Chart?'
  }, {
    id: 'why-are-pie-charts-useful',
    name: 'Why Are Pie Charts Useful?'
  }, {
    id: 'when-to-use-a-pie-chart',
    name: 'When to Use a Pie Chart'
  }, {
    id: 'how-to-create-a-pie-chart-online',
    name: 'How to Create a Pie Chart Online'
  }, {
    id: 'how-to-make-a-pie-chart-in-excel',
    name: 'How to Make a Pie Chart in Excel'
  }, {
    id: 'how-to-make-a-pie-chart-in-google-sheets',
    name: 'How to Make a Pie Chart in Google Sheets'
  }, {
    id: 'free-pie-chart-maker',
    name: `${generalConfig.productName} Free Pie Chart Maker`
  }]
};